@import "variables";
/*Bootstrap overrides*/

@media all and (min-width: 1500px) {
  .header {
    margin-left: 6.5%;
    max-width: 94%;
  }
  .leftMenu {
    width: 6.5%;
  }
  .site-footer, .site-content {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 890px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1070px;
  }
}


@media all and (max-width: 1490px){
  .container-fluid {
    max-width: inherit;
    .main-content {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media all and (max-width: 1280px) {
  .container-fluid {
    .leftMenu {
      width: 90px;
    }
  }
}
@media all and (max-width: 1199px) {
  body {
    .upload-picture {
      width: 205px;
      background: url('../assets/images/upload.svg') 6px 9px no-repeat;
      background-size: 14px;
      &.uploaded {
        background: url('../assets/images/correct.svg') 6px 9px no-repeat;
        background-size: 14px;
      }
    }
  }
  .main-content {
    .form-group {
      max-width: 205px;
    }
    table {
      th {
        &.cell-correction {
          width: 220px;
        }
        &:last-child {
          width: 200px;
        }
      }
    }
  }


}
@media all and (max-width: 1100px) {
  .container-fluid {
    .leftMenu {
      width: 85px;
    }
  }
}

@media all and (max-width: 991px) {
  .container-fluid {
    .main-content {
      padding-left: 15px!important;
      padding-right: 15px!important;
      .form-group {
        max-width: 160px;
        .label-for-input {
          font-size: 14px;
        }
      }
      table {
        th {
          &.cell-correction {
            width: 140px;
          }
          &:last-child {
            width: 190px;
          }
        }
      }
    }
    .leftMenu {
      width: 8.33%;
    }
  }
  .close-modal-window {
    font-size: 30px!important;
  }
}

@media all and (max-width: 767px) {
  .site-content {
    margin-top:60px;
  }
}

@media all and (max-width: 768px) {
  .container-fluid{
    .main-content {
      .actions-and-search {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        .actions-without-search {
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          width: 100%;

          .select-actions {
            .action-select-element {
              width: 100%;
            }
          }
        }

        .form-group {
          max-width: 100%;
          margin: 10px 0;
        }
        .search-form, .select-actions {
          width: 100%;
        }

        .search-container .search-input {
          width: 100%;
        }

        .search-form {
          margin-bottom: 20px;
        }

      }
    }
    .label-period-form {
      margin-top: 0;
    }
  }
  .form-group {
    max-width: 100%;
    margin: 10px 0;
  }
  .site-content {
    .btn {
      width: 100%;
      &.btn-primary {
        width: 100%;
        margin: 10px 0;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .labelUnderSelect {
    font-size: 18px!important;
  }
}

/*RESPONSIVE LEFT MENU*/
.MuiBackdrop-root, .MuiDrawer-root.MuiDrawer-modal {
  top:60px!important;
}
#site-nav-responsive {
  span{
    color: #ffffff;
    &.IconWrapper {
      border-radius: 50%;
    }
    img {
      width: 35px;
      height: 35px;
    }
  }
}
.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  h6 {
    margin-bottom: 0;
    margin-right: -20px;
  }
  .avatar-wrapper {
    width: 36px;
    height: 36px;
    overflow: hidden;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
      background-color: #fff;
    }
  }
}
/*!!!!!!!!RESPONSIVE LEFT MENU*/
