
.MessageRecipientSearchResult{
    max-height: 170px;
    overflow: auto;
    div{
        padding:0.44rem 0.75rem;
        & + div{

            margin-top: 1em;
        }
    }
    
}