@import "css-theme-vaiables";
$mainThemeColor: var(--default);

$green: rgba(95,123,54,1);
$green-light:#5f7a16;
$green-dark:#252E08 ;
$lightgray:#eee;
$gray:#eee;
$graybitdarker:#999999;
$main-gray:#D8D8D8;
$text-black:#4A4A4B;
$darkgray:#7D7C7F;
$blue: #4E80B3;
$orange: #CD6D2C;
$white: #fff;

$navIconBackgroundDark: #0E1204;

$darkblue: #123273;
$lightblue: #72A0A0;
$darkgreen: #365953;
$lightgreen: #8BA68A;
$maroon: #732210;
$lightred: #733636;
$purple: #754896;
$pink: #BF6374;
$lightorange: #EC7964;
$sandyyellow: #BE9900;

/*bootstrap variable overwrite*/
$primary: $green;
$secondary: $lightgray;
$danger:#ff0000;
$info: $green-dark;
$dark: #1A1A1A;
$btn-padding-y:0;
$btn-padding-y-sm: 0;

$theme-colors: (
  "gray": $gray,
  "darkgray": $darkgray,
  "graybitdarker":$graybitdarker,
  "blue":$blue,
  "orange":$orange,
  "green-light":$green-light,
);

:export {
  default: $green;
  darkblue: $darkblue;
  lightblue: $lightblue;
  darkgreen: $darkgreen;
  lightgreen: $lightgreen;
  maroon: $maroon;
  lightred: $lightred;
  purple: $purple;
  pink: $pink;
  lightorange: $lightorange;
  sandyyellow: $sandyyellow;
};

$color-inactive-grey: #d8d8d8;
$color-table-select-grey: #e4e4e6;

