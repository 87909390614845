.user-profile {
  .user-profile-wrapper {
    width: 114px;
    height: 114px;
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    overflow: hidden;
    position: relative;
    img {
      width: 114px;
      height: 114px;
      background-color: #fff;
      object-fit: cover;
      position: absolute;
      z-index: 1;
    }
  }
  .user-profile-border {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: 2px dashed #fff;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    &:hover {
      background-color: rgba(0,0,0,0.5);
      .btn-text {
        display: block;
      }
    }
    .btn-text {
      color: #fff;
      display: none;
      z-index: 10;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
