@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/font-awesome/css/font-awesome.min.css';

@import 'base';
@import 'alert';
@import 'modal';
@import 'nav';
@import 'searchResult';
@import 'message';
@import 'teacher';
@import "table";
@import "inputs";
@import 'responsive';
@import 'toggleButton';

//create all color fill for svg
@each $color, $value in $theme-colors{
    .fill-#{$color} path{
      fill:$value;
    }
  }
