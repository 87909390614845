
$gray: #EAEAEA;

.MaterialInput{
    display:inline-flex;
    position: relative;
    flex-direction: column;
    vertical-align: top;
    border: 1px solid var(--graybitdarker);
    padding: 0.4rem 1rem;
    border-radius: 0.25rem;
    width:100%;
}

.MaterialInput-label{
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    margin:0;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transform: translate(0, 1rem) scale(1);
    transform-origin: top left;
    &.shrink{
        transform: translate(0, 1.5px) scale(0.75);
        
    }
}

.MaterialInput-input{
    border:0;
    background-color: transparent;
    width:100%;
    color:#6B6B6B;

    &:focus{
        border:0;
        outline-width: 0;
    } 

}