@import "./variables";

.form-group {
  margin-bottom: 0;
  margin-top: 15px;
}
.label-for-input {
  font-weight: 700;
  color: $text-black;
  font-size: 14px;
  position: absolute;
  margin-top: -19px;
  line-height: 19px;
  label {
    margin-bottom: 0.2rem;
  }
}
.label-for-input.label-fix {
  margin-top: -4px;
}
.MuiTextField-root {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $color-table-select-grey;
  }
}
