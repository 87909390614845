#profile-editor-content {
    .content-wrapper {
        padding: 0px 60px 30px 60px;
        .form-group {
            max-width: 100%;
            margin-right: 0px;
            margin-left: 0px;
        }
    }
    .section-title {
        color: #4A4A4B;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: -0.12px;
        line-height: 26px;
    }
    .signature-upload {
        height: 37px;
        span {
            font-size: 14px;
        }
    }

    .signature-image-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            border: none;
            background-color: transparent;
            height: 80px;
        }
    }

    .separator {
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border: 1px dashed #E4E4E6;
        margin-top: 24px;
    }

    .email-update-message {
        margin-top: 5px;
        color: #4A4A4B;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
    }
}

.profile-editor-wrapper {
    width: 656px;
    padding: 55px 0px 0px 0px;
}
