.NotFound{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .StatusCode{
        font-size: 6rem;
        display:flex;
        align-items: center;
        .StatusCode-Zero{
            font-size: 20rem;
            line-height: 16rem;
        }
    }

    .OverlayGroup{
        position: absolute;
        width:30%;
        height: 100vh;
        left:-21%;

        .Overlay{
            width:100%;
            height:100%;
            position: absolute;
        }
        .darkGreenOverlay{

            background-color: rgba(26,37,3, 0.8);
            transform: skew(-12deg);
            z-index: 2;
        }

        .GreenOverlay{
            background-color: #BEC9A1;
            transform: skew(12deg);
        }
    }
}