

.SerachSummary{
    display:flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 0;
    border-bottom: 1px solid $gray;
    &.active{
        background-color: #E0E0E0;
    }
}