.modal-window {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-overlay {
  z-index: 2001;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.modal-container {
  width: 60%;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
  padding: 55px 50px 20px 50px;
  background: #ffffff;
  border-radius: 5px;
  z-index: 2005;
  .MuiButtonBase-root.text-right {
    right: 5px;
    top: 4px;
    position: absolute;
  }
}
.close-modal-window {
  font-size: 35px;
}
.MuiPopover-root {
  z-index: 3000!important;
}

