.forgotPasswordContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.passwordIcon {
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 0.3rem;
}

.passwordModal{
    min-width: 0;
    width:350px;
}


.modalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .passwordIcon{
        width: 3rem;
        height: 3rem;
        margin:1rem;
    }
}

