table {
  .MuiTableRow-root.MuiTableRow-hover:hover .tooltipIcons {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .MuiIconButton-root {
    width: 35px;
    height: 35px;
    padding: 0px;
    &:hover {
      background-color: #C6C6C6;
      width: 35px;
      height: 35px;
    }
  }
  th {
    &.cell-correction {
      width: 250px;
    }
  }
  td {
    &:last-child {
      position: relative;
    }
    .tooltipIcons {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      height: 100%;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      .MuiIconButton-root {
        margin-right: 8px;
      }
      div {
        height: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
