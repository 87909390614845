@import "variables";
html,
body {
  min-height:100vh;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  overflow-x: hidden;
  padding-right: 0!important;
  .upload-picture {
    width: 237px;
    margin-top: 30px;
    text-transform: initial;
    background: url('../assets/images/upload.svg') 11px 8px no-repeat;
    background-size: 16px;
    &.uploaded {
      background: url('../assets/images/correct.svg') 11px 8px no-repeat;
      background-size: 16px;
    }
  }
  b, strong{
    font-weight: 700;
  }
  input:focus, textarea:focus, select:focus, fieldset:focus, button:focus, div:focus {
    outline: none;
  }
}
.ReactModalPortal .ReactModal__Overlay{
  z-index: 2000;
}
pre{
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
}

select{
  color:gray !important
}

.text-capitalize{
  text-transform: capitalize
}


.font-weight-medium{
  font-weight: 500
}
.fa-stack {
  border-radius: 50%;
}

.btn {
  min-width: 107px;
  font-size: 14px;
  line-height: 14px;
  height: 32px;
  border-radius: 20px;
  letter-spacing: -0.09px;
  font-weight: 300;
  outline: none;
  &:focus, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2em rgba(224, 224, 224, 0.55);
  }
  &.rounded-circle{
    padding: 0;
    width:2rem;
    height:2rem;
    min-width: initial;
    display: flex;
    justify-content: center;

    &.btn-sm{
      width:1.5rem;
      height:1.5rem;
    }
  }
}

.btn-text {
  border: 0;
  background-color: transparent;
  font-weight: 300;
  font-size: 14px;
  color: $text-black;
  &:focus {
    outline: none;
  }
}

.outline-none:focus{outline:none}

.center-item-veri-horiz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-content {
  margin-bottom: 4vh;
  margin-top: 213px;
  min-height: 85vh;

  .ContentBody {
    border-top: 5px solid $green;
  }
}

.site-footer {
  min-height:11vh;
  border: 1px solid $color-table-select-grey;
  border-top: 2px solid $color-table-select-grey;
  margin-left: -5px;
  margin-right: -5px;
  padding: 0 10px;
}

.header-separator {
  -webkit-box-shadow: 0 0 14px -6px rgba(0,0,0,0.4);
  -moz-box-shadow: 0 0 14px -6px rgba(0,0,0,0.4);
  box-shadow: 0 0 14px -6px rgba(0,0,0,0.4);
  padding-bottom: 25px;
}
.header {
  background: #fff;
  margin-left: 7.1%;
  max-width: 92.9%;
  .header-logo {
    margin: 0 auto;
    max-width: 400px;
    .logo-image {
      &.empty-crest{
      background: url('../assets/images/shield.svg') no-repeat 140px 18px;
        background-size: 120px;
      }
      img {
        height: 97px;
        margin-top: 20px;
      }
      height: 120px;
      max-width: 400px;
    }
  }
  .top-menu {
    position: relative;
    display: flex;
    justify-content: center;
    .under-top-menu {
      position: absolute;
      width: 80%;
      height: 0;
      border: 2px solid;
      top: 10px;
    }
    color: $darkgray;
    &:hover {
      color: #575658;
    }
  }
  .TabContainer{
    display:flex;
    padding:0.3rem 0;
    align-items: center;
    justify-content: center;
    background: $color-table-select-grey;
    margin-top: 25px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    outline: 1px solid $color-table-select-grey;

    .Tab{
      display: flex;
      padding: 0.5rem 0.3rem;
      margin: 0 1rem;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 400;

      a:hover{
        text-decoration: none;
      }
      &.active{
        border-radius: 0.5em;
        border:1px solid white;
      }
    }

  }
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.form-group {
  margin: 35px 4px 0;
  &.without-left-gutters {
    margin-left: 0;
  }
  &.without-right-gutters {
    margin-right: 0;
  }
  &.without-top-gutters {
    margin-top: 0;
  }
}
.label-optional {
  font-size: 14px;
  color: #4A4A4A;
  font-style: italic;
  font-weight: 400;
}

.input-description {
  color: #4A4A4A;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  margin-top: 5px;
}

.description-text {
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: -0.09px;
}

.main-content {
  margin-left: -15px;
  margin-right: -15px;
  .MuiPaper-elevation1 {
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.2), 0 1px 0 0 rgba(0,0,0,0.14), 0 1px 0 0 rgba(0,0,0,0.12);
  }
  .MuiCheckbox-colorSecondary.Mui-checked, .MuiCheckbox-root {
    color: $graybitdarker;
  }
  .MuiTypography-body2, .MuiTablePagination-input {
    font-size: 0.8rem;
    //color: $graybitdarker;
  }
  .actions-and-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: flex-end;
    margin-top: 20px;
  }
  .grey-bottom-border {
   border-bottom: 1px solid $color-table-select-grey;
  }
  .grey-top-border {
   border-top: 1px solid $color-table-select-grey;
  }
  .add-student-container {
    height: 80px;
    padding-top: 22px;
  }
  .close-form-icon {
    border-radius: 20px;
    padding: 0;
    color: #fff;
    position: absolute;
    right: 15px;
    top: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      height: 18px;
    }
  }

}

.upload-signature-filed {
  height: 37px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.customInput {
  p {
    color: $text-black;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0 0 2px;
  }
  input {
    box-sizing: border-box;
    height: 37px;
    width: 230px;
    border: 1px solid #E4E4E6;
    border-radius: 4px;
    padding: 9px 9px 9px 15px;
    &::placeholder {
      color: $main-gray;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
}

.customSelect {
  select {
    box-sizing: border-box;
    height: 37px;
    width: 230px;
    border: 1px solid #E4E4E6;
    border-radius: 4px;
    padding: 9px 9px 9px 9px;
    color: $gray;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.18px;
    line-height: 19px;
  }
  p {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.80);
    font-size: 15px;
    margin: 0 0 3px 0;
  }
}

.school-list-item {
  cursor: pointer;
}

.site-list {
  padding: 0;
  li {
    display: flex;
    list-style-type: none;
    width: 100%;
    //cursor: pointer;

    + li{
      margin-top:1em;
    }

    .site-list-text {
      width: 85%;
      padding:0.44rem 0.75rem;
    }

  }
}

.site-list-delete .ConfirmContainer{
  position: relative;
  left: -3px;
}


.site-pagination {
  display: flex;
  align-items: center;

  > * {margin-right:0.4em;}
}

.list-avatar {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: relative;
  img {
    z-index: 1;
    background-color: #fff;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
}


.icon{
  width:2em;
  height:2em;
}

.SectionTitleIcon{
  width: 6em;
  height: auto;
  opacity: 0.5;
  padding-top:1em;
}

.SectionTitle{
  font-weight: 300;
  letter-spacing: 1px;
  padding-bottom: 0.4em;
}

.cursor-pointer{cursor: pointer;}
.form-control:focus {
  border: rgba(224, 224, 224, 0.55);
  box-shadow: 0 0 0 0.2em rgba(224, 224, 224, 0.55);
}
.placeHolderInput{
  @extend .form-control, .px-0, .rounded-0 , .border-top-0, .border-left-0, .border-right-0;
  background-color: transparent;
  border-color:$dark;

  &.error{
    border-color:$danger;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $danger;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $danger;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $danger;
    }
  }


}



.DeleteButton{
  display:flex;
  align-items: center;
  height: 100%;
  .ConfirmContainer{
    display: flex;
    height: 100%;

  }

  .ConfirmIcon{
    width:2.5em;
    display:flex;
    justify-content: center;
    align-items: center;
    color:white;
    position: relative;

    &::after{
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent $darkgray;
    }

  }

  .ConfirmButton{
    height:auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left:0;
    padding:0;
  }
}
.tooltipbox:before {
  content: '';
  display: block;
  position: absolute;
  left: 14px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: $danger;
}

.tooltipbox:after {
  content: '';
  display: block;
  position: absolute;
  left: 15px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}
.form-group {
  &.with-error {
    .label-for-input {
      label {
        color: $danger;
      }
    }
  }
}
.set-of-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  .btn.btn-secondary {
    margin-right: 20px;
  }
}
.label-period-form {
  font-weight: 400;
  margin-top: 24px;
}

