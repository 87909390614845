.ReactModal__Html--open,
.ReactModal__Body--open{
    overflow: hidden;
}

.ReactModal__Overlay{
  z-index: 10;
  background-color: rgba(0,0,0,0.4) !important;
}
  
.Modal{
  border-radius: 0.3rem;
  position: absolute;
  top: 50%;
  left:50%;
  right:auto;
  bottom:auto;
  margin-right:-50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding:20px;
  background-color: white;
  min-width: 30%;

  .close{
    position: absolute;
    right: 0.5em;
    top:0.2em;
    z-index: 2;
    span{
      font-weight: bold;
      border:0;
      text-shadow: none
    }
  }
}



.modal-report {
  &.ReactModal__Content {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    width: 100%;
    max-width: 960px;
    margin-bottom: 50px;
    padding: 0 0 36px;
    background-color: #ffffff;
  }

  &-overlay {
    &.ReactModal__Overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      background-color: rgba(0, 0, 0, 0.8)!important;
    }
  }

  iframe {
    border: none;
  }
}

.modal-close-button {
  position: absolute;
  top: 33px;
  right: 60px;
  width: 42px;
  height: 42px;
  padding: 0;
  margin: 0;
  background: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%234A4A4B' stroke-width='8' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpath d='M161.46 36.53846154L39.54 163.461538M39.54 36.53846154L161.46 163.461538'/%3E%3C/g%3E%3C/svg%3E") no-repeat center / 100% auto;
  border: none;
  outline: none;

  &:hover {
    opacity: 0.85;
  }
}

/* pdf-footer */
.pdf-footer {
  margin-top: 37px;

  &__buttons-list {
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 300;

    &-item {
      width: 80px;
      padding: 0 5px;
    }
  }

  &__button {
    margin: 0 auto;
    font: 300 14px/19px Roboto, sans-serif;
    letter-spacing: -0.1px;
    color: #4A4A4B;
    background-color: transparent;
    border: none;
    outline: none;

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &-wrap {
        position: relative;
        display: inline-block;
        width: 28px;
        height: 28px;
        background: transparent no-repeat center / 20px auto;
        vertical-align: top;
        border-radius: 14px;
        transition: all .15s ease-in-out;
      }

      &.fa-spinner.fa-spin {
        top: 7px;
        left: 5px;
      }
    }

    &:hover {
      .pdf-footer__button {
        &-icon {
          &-wrap {
            background-color: #E4E4E6;
          }
        }
      }
    }
  }
}

.icon-cancel {
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M152.68 93.18h-45.86V47.32c0-3.76658199-3.053418-6.82-6.82-6.82s-6.82 3.05341801-6.82 6.82v45.86H47.32c-3.76658199 0-6.82 3.053418-6.82 6.82s3.05341801 6.82 6.82 6.82h45.86v45.86c0 3.766582 3.053418 6.82 6.82 6.82s6.82-3.053418 6.82-6.82v-45.86h45.86c3.766582 0 6.82-3.053418 6.82-6.82s-3.053418-6.82-6.82-6.82z' fill='%234A4A4B' fill-rule='nonzero'/%3E%3C/svg%3E");
  transform: rotate(-45deg);
  background-size: 36px auto;
}

.icon-update {
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%234A4A4B' fill-rule='nonzero'%3E%3Cpath d='M31 92.6601636c1.3121371.006478 2.6174596-.1891517 3.87-.5801636l25.73-7.87c6.4048558-1.9869374 10.3154367-8.4458528 9.1073133-15.0421045C68.4991899 62.5716438 62.5533048 57.9179698 55.86 58.33 66.8046759 45.258358 82.951624 37.6714141 100 37.59c26.891486-.1447947 50.241934 18.4872549 56.07 44.74 1.344914 6.0552702 6.727222 10.3552894 12.93 10.3301636.981938-.0027912 1.960799-.1100636 2.92-.3201636 7.13065-1.6195611 11.604357-8.7059141 10-15.84-7.736018-33.967429-35.635671-59.63467651-70.128995-64.51758706C77.2976808 7.0995024 43.3700534 24.0144163 26.51 54.5l-3.16-3.54c-2.3343413-2.6274989-5.6188838-4.2172928-9.1278136-4.4180711C10.7132567 46.3411506 7.26881154 47.5459168 4.65 49.89c-5.4314123 4.8695741-5.90951448 13.2117868-1.07 18.67l17.54 19.67c2.5106164 2.818427 6.1055165 4.4301636 9.88 4.4301636zM196.42 131.44l-17.55-19.68c-3.444352-3.8486019-8.809955-5.3470684-13.75-3.84l-25.72 7.87c-3.362328 1.020075-6.180252 3.336707-7.831401 6.438241-1.65115 3.101533-1.999674 6.732788-.968599 10.091759 1.82384 5.863209 7.421934 9.717837 13.55 9.33-10.940556 13.085094-27.093941 20.68066-44.15 20.76-26.896915.144684-50.2517768-18.491729-56.08-44.75-1.7595131-6.9700923-8.7560019-11.2657087-15.7678987-9.6809766C21.1402044 109.5637555 16.6710393 116.450681 18.08 123.5c7.7360184 33.967429 35.6356711 59.634677 70.1289951 64.517587C122.702319 192.900498 156.629947 175.985584 173.49 145.5l3.16 3.54c4.865629 5.459334 13.235665 5.940628 18.695 1.075 5.459334-4.865629 5.940628-13.235665 1.075-18.695v.02z'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-download {
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%234A4A4B' fill-rule='nonzero'%3E%3Cpath d='M136.8 99.06c-.754334-1.6639004-2.413095-2.7319234-4.24-2.73H114V45.16c-.027545-7.71245311-6.287498-13.95-14-13.95-7.7319865 0-14 6.2680135-14 14v51.12H67.44c-1.80801.0138866-3.4440477 1.0745512-4.1946525 2.719448-.7506048 1.6448968-.4796699 3.5757575.6946525 4.950552l32.56 37.26c.8829896 1.009476 2.1588393 1.588546 3.5 1.588546s2.6170104-.57907 3.5-1.588546L136.06 104c1.189949-1.3609813 1.478923-3.2900783.74-4.94z'/%3E%3Cpath d='M168.67 149.94v25H31.33v-25c-.0055197-6.894131-5.5958668-12.48-12.49-12.48-6.89251368 0-12.48 5.587486-12.48 12.48v37.46c0 6.892514 5.58748632 12.48 12.48 12.48h162.32c6.892514 0 12.48-5.587486 12.48-12.48v-37.46c0-6.892514-5.587486-12.48-12.48-12.48-6.894133 0-12.48448 5.585869-12.49 12.48z'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-send {
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M185.74 5.77L5.2 107.32c-3.12979041 1.762003-4.95148743 5.181155-4.66813878 8.761651C.81520988 119.662148 3.15202442 122.752209 6.52 124l44.66 16.54c3.277476 1.216853 6.9613486.473106 9.51-1.92L162.48 43.2 81.89 151.93h.07-.08v34.27c.0067023 3.857366 2.4114351 7.30377 6.0294871 8.641313 3.6180519 1.337542 7.6863098.28411 10.2005129-2.641313l22.29-26 38.45 14.24c2.558717.948918 5.40619.716894 7.777528-.633747 2.371339-1.35064 4.023424-3.681424 4.512472-6.366253l28.21-158c.628836-3.51642335-.827857-7.07998338-3.739877-9.14899418C192.698103 4.22199501 188.853661 4.01906172 185.74 5.77z' fill='%234A4A4B' fill-rule='nonzero'/%3E%3C/svg%3E");
}

@media screen and (max-width: 767px) {
  .modal-report {
    &.ReactModal__Content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
