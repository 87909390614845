

.Login-page{
    height: 100vh;
    width:100vw;
    display: flex;
    flex-direction: row;

    .PanelLogin{
        width:40%;
        padding-left:10%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .login-card {
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }


        .Logo{
            width:100px;
            height: 100px;
            margin-bottom: 2rem;
        }

        .welcome{
            font-weight: normal;
            letter-spacing: 0.5px;
            color:#6B6B6B;

        }

        .signInMsg{
            margin-bottom: 2rem;
            text-align: center;
        }

        .btn-enter{
            width:13rem;
            font-size: 1rem;
            border-radius: 2rem;
        }

        .LoginCopyRight{
            position: absolute;
            bottom:5%;
            color: #252E08;
        }

    }

    .PanelImage{
        width:60%;
        img{
            height: 100%;
            width:100%;

        }
    }


}

@media screen and (max-width: 992px) {
    .Login-page {
        .PanelLogin {
            padding-left:0;
            width: 100%;
            .login-card {
                background: rgba(255,255,255, 0.9);
                border-radius: 10px;
                padding: 25px;
                border: 1px solid #dbdcd6;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
                width: 480px;
            }
            .LoginCopyRight{
                color: rgba(255,255,255, 0.9);
            }
        }
        .PanelImage{
            position: absolute;
            width: auto;
            height: 100%;
            z-index: -1;
            right: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .Login-page {
        .PanelImage{
            margin-left: -150px;
        }
    }
}

@media screen and (max-width: 576px) {
    .Login-page {
        .PanelLogin {
            .login-card {
                padding: 25px 15px;
                width: 80%;
            }
        }
        .PanelImage {
            margin-left: -100px;
        }
    }
}

@media screen and (max-width: 461px) {
    .Login-page {
        .PanelLogin {
            .login-card {
                padding: 25px 15px;
                width: 94%;
            }
            .btn-enter{
                width: 100%;
            }
        }
        .PanelImage {
            margin-left: -350px;
        }
    }
}

@media screen and (max-height: 650px) {
    .Login-page {
        .PanelLogin {
            .LoginCopyRight {
                bottom: 2%;
            }
        }
    }
}

@media screen and (max-height: 570px) {
    .Login-page {
        .PanelLogin {
            justify-content: flex-start;
            padding-top: 5%;
            .Logo{
                margin-bottom: 15px;
            }
            .signInMsg{
                margin-bottom: 15px;
            }
            .LoginCopyRight {
                bottom: 5%;
            }
        }
    }
}

@media screen and (max-height: 530px) {
    .Login-page {
        .PanelLogin {
            padding-top: 5%;
            .Logo{
                height: 80px;
                width: 80px;
                margin-bottom: 15px;
            }
            .signInMsg{
                margin-bottom: 10px;
            }
            .LoginCopyRight {
                bottom: 4%;
            }
        }
    }
}

@media screen and (max-height: 530px) {
    .Login-page {
        .PanelLogin {
            .LoginCopyRight {
                bottom: 2%;
            }
        }
    }
}
