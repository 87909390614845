
$optionFontSize: 1.33333em;
.leftMenu {
    width: 106px;
    z-index: 2000;
}
#site-nav{
    color:white;
    height:100%;
    a{
        color:white;
    }
    #Profile{
        height:20%;
        color:$green;
        text-align: center;
        padding-top: 50px;
        .IconWrapper {
            display: inline-block;
            border-radius: 50%;
            img {
                width: 50px;
                height: 50px;
            }
        }


        a{
            text-decoration: none;
        }
        .HeadShotWrapper{
            border: 4px solid $green-dark;
            border-radius: 50%;
        }
        .fa-stack{
            font-size:$optionFontSize * 2;
            border-width: 3px;

            i{
                color:$green;
                font-size:$optionFontSize * 1.5;
            }
            &.hover{
                background-color: $green;
            }

        }
        span{font-size:1.5em; color:white;}
        .ProfileIconText{font-size: initial; line-height: initial; text-decoration: none;}
    }

    #Options{
        display: block;
        text-align: center;
        margin-top: 14px;
        .IconWrapper {
            display: inline-block;
            border-radius: 50%;
            img {
                width: 50px;
                height: 50px;
            }
        }
        a{
            display:inline-block;
            margin: 5px auto;
            &:hover{
                text-decoration: unset;

            }
        }

        div:not(.active) a:hover{
            color:$green;
        }


        div.active{
            border:1px solid $green;
            border-right-width: 0px;
            border-top-left-radius:$optionFontSize;
            border-bottom-left-radius:$optionFontSize;
            position: relative;

            &::after{
                content:' ';
                width:2rem;
                height:2rem;
                border-style: solid;
                border-color: $green;
                border-width: 1px 1px 0 0;
                position: absolute;
                right:-1rem;
                top:0.3rem;
                transform: rotate(45deg);

            }
        }

    }

    #UserProfile{
        display: flex;
        justify-content: center;
        margin-top: 14px;
        margin-bottom: 15px;
        .IconWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            border: #fff 2px solid;
            border-radius: 50%;
            overflow: hidden;
            width: 54px;
            height: 54px;
            position: relative;
            &:hover p {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: rgba(0,0,0,0.5);
            }
            img {
                width: 54px;
                height: 54px;
                object-fit: cover;
                background-color: #fff;
                position: absolute;
                z-index: 1;
            }
            p {
                display: none;
                font-size: 12px;
                line-height: 15px;
                z-index: 5;
                width: 100%;
                height: 100%;
            }
        }
    }

    #logout{
        height: 23%;
        display:flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 10px;
        #Settings {
            margin-bottom: 15px;
        }
        .IconWrapper {
            display: inline-block;
            border-radius: 50%;
            img {
                width: 40px;
                height: 40px;
            }
        }
        .fa-stack{
            color:black;
            background-color: red;
        }

        p{
            padding-top:0.6em;
        }

    }

}

@media all and (max-height: 800px){
    #site-nav {
        #Profile {
            height: 19%;
        }
        #logout {
            height: 20%;
        }
    }
}

@media all and (max-height: 730px){
    #site-nav {
        #Profile {
            height: 17%;
        }
        #logout {
            height: 19%;
        }
    }
}

@media all and (max-height: 660px){
    #site-nav {
        #Profile {
            height: 15%;
        }
        #logout {
            height: 17%;
        }
    }
}
